<template lang="pug">
  v-app
    .d-flex.flex-column.quiz-indicator
      b(v-html="$t('quiz.level.finish.congratulations.text')")
      img(
        :src="require(`@/assets/images/quiz/quiz_level_finish_${$store.getters.currentLocale}.png`)"
      )
    //- img(
    //-   class="draw-button"
    //-   @click="drawStart = true"
    //-   :src="require(`@/assets/images/quiz/tekan_${$store.getters.currentLocale}.png`)"
    //- )
    //- v-dialog(
    //-   v-model="drawStart"
    //-   max-width="600"
    //- )
    //-   v-card(
    //-     class="lucky-draw"
    //-     style="background: #F5F5F5"
    //-     v-if="!tnc && !win && !lose"
    //-   )
    //-     v-card-title
    //-       h3
    //-         b {{ $t("lucky-draw.title.text") }}
    //-       v-spacer
    //-       v-icon(
    //-         @click="drawStart = false"
    //-         color="primary"
    //-         v-bind="size"
    //-       ) mdi-close-box
    //-     v-card-text.d-flex
    //-       .roulette-container
    //-         .roulette#roulette
    //-           img.pointer(src="@/assets/images/quiz/lkd/lkd_pointer.png")
    //-           img.wheel(
    //-             id="wheel"
    //-             :src="wheelImage"
    //-           )
    //-     v-card-actions.d-flex.flex-column
    //-       v-btn(
    //-         v-if="!$store.getters.canLuckyDraw"
    //-         class="m-auto mb-2"
    //-         color="primary"
    //-         @click="drawPrize"
    //-         v-bind="size"
    //-       )
    //-         b {{ $t("lucky-draw.start.text") }}
    //-       v-btn(
    //-         v-else
    //-         class="m-auto mb-2"
    //-         color="primary"
    //-         @click="lose = true"
    //-         v-bind="size"
    //-       )
    //-         b {{ $t("lucky-draw.tomorrow") }}
    //-       v-btn(
    //-         class="m-auto mb-2"
    //-         color="secondary"
    //-         @click="tnc = true"
    //-         text
    //-         dense
    //-       ) 
    //-         b {{ $t("lucky-draw.tnc.button") }}
    //-   v-card(
    //-     class="lucky-draw"
    //-     style="background: #F5F5F5"
    //-     v-else-if="!tnc && win"
    //-   )
    //-     v-card-title
    //-       h3
    //-         b {{ $t("lucky-draw.win.title") }}
    //-       v-spacer
    //-       v-icon(
    //-         @click="drawStart = false"
    //-         color="primary"
    //-         v-bind="size"
    //-       ) mdi-close-box
    //-     v-card-text.d-flex
    //-       img(:src="winImage")
    //-     v-card-actions.d-flex
    //-       v-btn(
    //-         class="m-auto mb-2"
    //-         @click="drawStart = false"
    //-         color="primary"
    //-         v-bind="size"
    //-       ) 
    //-         b {{ $t("lucky-draw.win.back") }}
    //-   v-card(
    //-     class="lucky-draw"
    //-     style="background: #F5F5F5"
    //-     v-else-if="!tnc && lose"
    //-   )
    //-     v-card-title
    //-       h3
    //-         b {{ $t("lucky-draw.lost.title") }}
    //-       v-spacer
    //-       v-icon(
    //-         @click="drawStart = false"
    //-         color="primary"
    //-         v-bind="size"
    //-       ) mdi-close-box
    //-     v-card-text.d-flex
    //-       img(:src="loseImage")
    //-     v-card-actions.d-flex
    //-       v-btn(
    //-         class="m-auto mb-2"
    //-         @click="drawStart = false"
    //-         color="primary"
    //-         v-bind="size"
    //-       ) 
    //-         b {{ $t("lucky-draw.lost.back") }}
    //-   v-card(
    //-     class="lucky-draw"
    //-     v-if="tnc"
    //-   )
    //-     v-card-title
    //-       h3
    //-         b(v-html="$t('lucky-draw.tnc.title')") 
    //-       v-spacer
    //-       v-icon(
    //-         @click="drawStart = false"
    //-         color="primary"
    //-         v-bind="size"
    //-       ) mdi-close-box
    //-     v-card-text
    //-       .tnc-container(
    //-         v-html="$t('lucky-draw.tnc.text')"
    //-       )
    //-     v-card-actions
    //-       v-btn(
    //-         class="m-auto mb-2"
    //-         color="primary"
    //-         @click="tnc = false"
    //-         v-bind="size"
    //-       )
    //-         b {{ $t("lucky-draw.tnc.back") }}
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  computed: {
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    wheelImage() {
      return require(`@/assets/images/quiz/lkd/wheel_${this.$store.getters.currentLocale}.png`);
    },
    winImage() {
      return require(`@/assets/images/quiz/lkd/lkd_win_${this.$store.getters.currentLocale}.png`);
    },
    loseImage() {
      return require(`@/assets/images/quiz/lkd/lkd_lost_${this.$store.getters.currentLocale}.png`);
    }
  },
  data() {
    return {
      drawStart: false,
      tnc: false,
      win: false,
      lose: false,
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  mounted() {
    // this.$store.commit("customer/deleteEntry");
    if (this.$store.getters.isWonPrize) {
      this.drawStart = true;
      this.win = true;
    }
  },
  methods: {
    drawPrizeTest() {
      const array = [
        "lose",
        "lose",
        "lose",
        "win",
        "lose",
        "lose",
        "lose",
        "lose",
        "lose",
        "lose"
      ];
      const random = Math.floor(Math.random() * array.length);
      this.spinWheel(array[random] == "win");
    },
    drawPrize() {
      let url = config.drawPrizeUrl();

      var json = {
        userId: this.$store.getters.userId
      };

      this.loading = true;

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            if (response) {
              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.spinWheel(response.body.data.isWon);
              } else {
                this.$swal(
                  this.$t("error.lucky_draw"),
                  response.body.error.message,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.lucky_draw"),
              response.body.error.message,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.lucky_draw"),
            response.body.error.message,
            "error"
          );
        });
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    spinWheel(win) {
      console.log(win);
      const box = document.getElementById("wheel");
      const element = document.getElementById("roulette");

      box.style.setProperty("transition", "initial");
      box.style.transform = "rotate(0deg)";

      var randomAngle = 0;
      const winAngle = [
        { min: -15, max: 15 },
        { min: 165, max: 195 }
      ];

      const loseAngle = [
        { min: 22, max: 57 },
        { min: 57, max: 93 },
        { min: 93, max: 129 },
        { min: 129, max: 165 },
        { min: 201, max: 237 },
        { min: 237, max: 273 },
        { min: 273, max: 309 },
        { min: 309, max: 345 }
      ];

      if (win) {
        const random = Math.floor(Math.random() * winAngle.length);
        randomAngle = this.random(winAngle[random].min, winAngle[random].max);
      } else {
        const random = Math.floor(Math.random() * loseAngle.length);
        randomAngle = this.random(loseAngle[random].min, loseAngle[random].max);
      }

      console.log(randomAngle);

      var prizeAngle = 360 * 3 + randomAngle;

      // spin
      box.style.setProperty("transition", "all ease 5s");
      box.style.transform = "rotate(" + prizeAngle + "deg)";
      element.classList.remove("animate");
      setTimeout(() => {
        element.classList.add("animate");
        this.$store.commit("customer/addEntry");
        if (win) this.$store.commit("customer/winPrize");
        if (win) this.win = true;
        else this.lose = true;
      }, 5000);
    }
  }
};
</script>

<style lang="sass" scoped>
.v-application
  min-height: 90vh !important
  overflow: hidden !important
</style>
